//Login page Api

const LOGIN_API_URL = process.env.REACT_APP_API_URL + "/auth/login";
export const LOGIN_API = `${LOGIN_API_URL}`;

export const LOGOUT_API = process.env.REACT_APP_API_URL + "/auth/logout";

// profile Update Api Url :

const PROFILE_UPDATE_URL = process.env.REACT_APP_API_URL + "/admin/update-profile";
export const PROFILE_API = `${PROFILE_UPDATE_URL}`;
// Update password Api

const UPDATE_PASSWORD_API_URL = process.env.REACT_APP_API_URL + "/admin/update-password";
export const UPDATE_PASSWORD_API = `${UPDATE_PASSWORD_API_URL}`;

export const CHANGE_PASSWORD_API = `${process.env.REACT_APP_API_URL}/auth/change-password`;

// Application API

const APPLICATION_LIST_API_URL = process.env.REACT_APP_API_URL + "/application";
export const APPLICATION_LIST = `${APPLICATION_LIST_API_URL}`;
export const APPLICATION_TRASH_LIST = process.env.REACT_APP_API_URL + "/application/trash-list";

export const APPLICATION_CREATE_API = process.env.REACT_APP_API_URL + '/application';


