import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";

function InfiniteScrollCustom(props) {
  const { t } = useTranslation();
  const {
    data = [],
    currentData,
    pageLimit,
    className,
    itemCount,
    page,
    maxPage,
    sendPage,
    endMessage,
    targetedElem,
    height,
    rowHeight,
    ...rest
  } = props;
  const [hasMore, setHasMore] = useState(false);
  const [containerHeight, setContainerHeight] = useState(rowHeight || 25);
  useEffect(() => {
    rowHeight
      ? setContainerHeight(data?.length * containerHeight)
      : setContainerHeight(height);
  }, [rowHeight]);
  const loaderIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const loadMore = () => {
    if (currentData) {
      sendPage(page + 1);
    } else if (page < maxPage) {
      sendPage(page + 1);
    }
  };
  const PER_PAGE = pageLimit || 9;
  useEffect(() => {
    if (currentData) {
      setHasMore(currentData?.length > PER_PAGE);
    } else if (itemCount) {
      setHasMore(data?.length < itemCount);
    } else {
      setHasMore(false);
    }
  }, [data, currentData, page, itemCount]);
  return (
    <div>
      <InfiniteScroll
        {...rest}
        className={className}
        dataLength={Array.isArray(data) ? data?.length : [data]?.length}
        next={() => loadMore()}
        hasMore={hasMore}
        height={data?.length > 0 ? height : "auto"}
        loader={
          <div className="text-center">
            <Spin indicator={loaderIcon} />
          </div>
        }
        scrollableTarget={targetedElem}
        endMessage={
          endMessage && data?.length > 10 ? (
            <div className="caught-panel them-bg">
              {t("Looks like you reached the end")}
            </div>
          ) : null
        }
      >
        {props?.children}
      </InfiniteScroll>
    </div>
  );
}

export default InfiniteScrollCustom;
