import React from 'react';
import { IMAGES } from '../../constants'
import './WrongApp.css';

const WrongApp = () => {
  return (
    <div className="container">
       <a className="navbar-brand">
            <img src={IMAGES.hidco} alt="hidco" width="220px"  />
          </a>
      <p>Please scan this QR code by using the "WBHIDCO Property Data Search Mobile App".</p>
      {/* If you have a QR code image, you can include it below. */}
      {/* <img src={YourQRCodeImageUrl} alt="QR Code" className="qr-code" /> */}
    </div>
  );
};

export default WrongApp;
