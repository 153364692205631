import React from 'react'
import './Policy.css'
const Policy = () => {
    return (
        <div className='div'>
            <h1 className='h1'>Privacy Policy</h1>
            <p className='p'>
                This Portal does not automatically capture any specific personal information from you, (like
                name, phone number or e-mail address), that allows us to identify you individually. If the
                West Bengal Housing Infrastructure Development Corporation (WBHIDCO) Portal requests
                you to provide personal information, you will be informed for the particular purposes for
                which the information is gathered and adequate security measures will be taken to protect
                your personal information.
                We do not sell or share any personally identifiable information volunteered on the West
                Bengal Housing Infrastructure Development Corporation (WBHIDCO) Portal site to any third
                party (public/private). Any information provided to this Portal will be protected from loss,
                misuse, unauthorized access or disclosure, alteration, or destruction.
            </p>

            <h3 className='h3'>Hyperlinking Policy (Links to external websites/portals)</h3>
            <p className='p'>
                At many places in this Portal, you shall find links to other websites/portals. This links have
                been placed for your convenience. The West Bengal Housing Infrastructure Development
                Corporation (WBHIDCO) is not responsible for the contents and reliability of the linked
                websites and does not necessarily endorse the views expressed in them. Mere presence of
                the link or its listing on this Portal should not be assumed as endorsement of any kind. We
                cannot guarantee that these links will work all the time and we have no control over
                availability of linked pages.
            </p>
            <h3 className='h3'>Copyright Policy</h3>
            <p className='p'>
                Material featured on this site may be reproduced free of charge in any format or media
                without requiring specific permission. This is subject to the material being reproduced
                accurately and not being used in a derogatory manner or in a misleading context. Where the
                material is being published or issued to others, the source must be prominently
                acknowledged. However, the permission to reproduce this material does not extend to any
                material on this site, which is explicitly identified as being the copyright of third party.
                Authorisation to reproduce such material must be obtained from the copyright holders
                concerned.
            </p>
        </div>
    )
}

export default Policy