import { Divider, Dropdown, Layout, Menu, Avatar } from "antd";
import { BrowserRouter, Route, useHistory, Switch } from "react-router-dom";
import { DeleteOutlined, UserOutlined } from "@ant-design/icons";
import Dashboard from "./index";
import { Col, Row } from "antd";
import LogoImage from "../../sharedComponents/LogoImage";
import { Footer } from "antd/es/layout/layout";
import { toast } from "react-toastify";
import ApplicationList from "./application/ApplicationList";
import ApplicationCreate from "./application/ApplicationCreate";
import { LOGOUT_API } from "../../all-api/api";
import "react-toastify/dist/ReactToastify.css";
import ApplicationTrashList from "./application/ApplicationTrashList";
import axios, { AxiosError }  from 'axios';
import ChangePassword from "./change-password/change-password";

const Home = () => {
  toast.configure({
    autoClose: 2500,
    draggable: false,
  });
  return (
    <>
      <Layout hasSider>
        <div style={{width: '100%'}}>
          <Header />
          <Divider
            type="vertical"
            dashed
          />
          <div style={{width: '100%', display:'flex', flexDirection: 'row'}}>
            <SideMenu />
            <BrowserRouter>
            <Content />
            </BrowserRouter>
          </div>
          <Footer className="footer-text">
            {" "}
            {`© ${new Date().getFullYear()} WBHIDCO Admin Panel `}
          </Footer>
        </div>
      </Layout>
    </>
  );
};

const SideMenu = () => {
  let history = useHistory();
  return (
    <>
      <Menu
        onClick={({ key }) => {
          history.push(key);
          window.location.reload(false);
        }}
        defaultSelectedKeys={[window.location.pathname]}
        items={[
          { label: "Dashboard",
            key: "/dashboard",
            className: "dashboard" },
          {
            label: "Application Listing",
            key: "/application-listing",
            icon: <UserOutlined />,
          },
          {
            label: "Trash",
            key: "/application-trash",
            icon: <DeleteOutlined />,
          },
        ]}
        style={{height:'80vh'}}
      ></Menu>
    </>
  );
};

const Content = () => {
  return (
    <div className="parrent-dashbord">
      <BrowserRouter>
        {/* <Switch> */}
        <Route exact path="/dashboard" render={() => <Dashboard toast={toast} />}>
          
        </Route>
        <Route exact path="/application-listing" render={() => <ApplicationList toast={toast} />}>
          
        </Route>
        <Route exact path="/application-create" render={() => <ApplicationCreate toast={toast} />}>
            
        </Route>
        <Route exact path="/application-trash" render={() => <ApplicationTrashList toast={toast} />}>
            
        </Route>
        <Route path="/change-password" render={(props) => {
          return <ChangePassword toast={toast} />
        }} />
        {/* </Switch> */}
      </BrowserRouter>
    </div>
  );
};

async function logoutAdmin(credentials) {
  //console.log(credentials);
  return fetch(LOGOUT_API, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      admin: true,
      "Accept-Language": "EN",
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
    body: "",
  }).then((data) => {
    if (data?.status === 200) {
      data.json();
      toast.success("You Have SuceessFully Logged out!!!!!!!");
      localStorage.removeItem("token");
      window.location.reload(false);
    }
  });
}

const logoutClick = async () => {
  try {
    const tokenString = localStorage.getItem("token");
    const response = await axios.post(`${LOGOUT_API}`, {}, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${tokenString}`
      }
    });
    toast.success("You Have SuceessFully Logged out!!!!!!!");
    localStorage.removeItem("token");
    window.location.reload(false);
  } catch(error) {
    if (error && error instanceof AxiosError) {
      if(error.status === 401) {
        // Logout
        localStorage.removeItem("token");
        window.location.reload();
        return;
      }
      if(error.response && error.response.data) {
        toast.error(error.response.data.message);
        localStorage.removeItem("token");
        window.location.reload();
        return;
      }
    }
    toast.error(error.toString());
    localStorage.removeItem("token");
    window.location.reload();
  }
};

const Header = () => {
  let history = useHistory();
  const overlay = () => (
    <Menu
      className="items-menu"
      onClick={({ key }) => {
        history.push(key);
        if (key === "/change-password") {
          window.location.reload(false);
        }
        if (key === "/profile") {
          window.location.reload(false);
        }
        if(key === '/logout') {
          logoutClick();
        }
      }}
      defaultSelectedKeys={[window.location.pathname]}
    >
 
      <Menu.Item  key={"/change-password"}>
        Change Password
      </Menu.Item>
      <Menu.Item  key={"/logout"}>
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Row>
        <Col span={18} push={6}>
          <div className="usericon">
            <Dropdown overlay={overlay()} trigger={["click"]}>
              <Avatar
                style={{ backgroundColor: "#87d068" }}
                icon={<UserOutlined />}
              />
            </Dropdown>
          </div>
        </Col>
        <Col span={6} pull={18}>
          <LogoImage />
          <p style={{textAlign:'centre', marginTop: '0px', fontWeight:'bold' }}>WBHIDCO Property Data Search</p>
        </Col>
      </Row>
    </>
  );
};

export default Home;
