import React, { useState } from 'react';
import { APPLICATION_CREATE_API } from '../../../all-api/api';
import { Button, Col, Input, Row, Card } from 'antd';
import { useHistory } from 'react-router-dom';
import axios, { AxiosError } from 'axios';
const ApplicationCreate = ({toast}) => {
  const [applicationId, setApplicationId] = useState('');
  const [qrImage, setQrImage] = useState(null);
  const [application, setApplicatopn] = useState({});
  const history = useHistory();
  const createApplication = async () => {
    try {
      const response = await axios.post(APPLICATION_CREATE_API, { applicationId }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      const data = response.data;
      if (data._id) {
        setApplicatopn(response.data);
        toast.success('Successfully created applicationId');
        history.push('/application-listing');
        window.location.reload();
      } else if(data.error){
        toast.error(data.message);
      }
    } catch (error) {
      if(error && error instanceof AxiosError) {
        if(error.status === 401) {
          // Logout
          localStorage.removeItem("token");
          history.push('/logout');
          window.location.reload();
          return;
        }
        if(error.response && error.response.data) {
          toast.error(error.response.data.message);
          return;
        }
      }
      toast.error(error.toString());
    }
  };

  const generateQR = async () => {
    try {
      const response = await fetch(`${ process.env.REACT_APP_API_URL }/application/${application._id}/generate-qr`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
      });

      if (response.ok) {
        const data = await response.json();
        setQrImage(data.qrImage);
      } else {
        throw response;
        // toast.error('Failed to generate QR code');
      }
    } catch (error) {
      if(error.status && error.status == 401) {
          localStorage.removeItem("token");
          history.push('/logout');
          window.location.reload();
          return;
      }
      toast.error(error.toString());
    }
  };

  return (
    <div>
      <h2 style={{ marginLeft :' 10px' }}>Create Application and Generate QR Code</h2>
      <div className='main-content'>
        <Card>
        <Row>
          <Col span={12}>
            <Input type='text' placeholder="Enter Application ID" value={applicationId} onChange={e => setApplicationId(e.target.value)} />
          </Col>
          <Col span={2}></Col>
          <Col span={10} >
            <Button type='primary' onClick={createApplication}>Submit Application ID</Button>
            {application._id &&  <Button type='default' onClick={generateQR}>Generate QR Code for Submitted ID</Button> }
          </Col>
        </Row>
        </Card>
      </div>
      {qrImage && (
        <div>
          <h3>Generated QR Code:</h3>
          <img src={qrImage} alt="QR Code" />
        </div>
      )}
    </div>
  );
};

export default ApplicationCreate;
