import { Button, Col, Row, Table, Upload, Modal, Space, Input } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { FileExcelOutlined, SearchOutlined } from '@ant-design/icons';
import { DownloadOutlined, UploadOutlined } from "@ant-design/icons";
import { notFoundContentComponent } from "../../../sharedComponents/NotContentFound";
import { TableContentLoaderWith } from "../../../sharedComponents/TableContentLoader";
// import InfiniteScrollCustom from "../../../sharedComponents/InfiniteScroll";
import { APPLICATION_LIST } from "../../../all-api/api"
import axios, { AxiosError } from 'axios';
import { Link } from "react-router-dom";
import { useHistory } from 'react-router-dom';
import debounce from 'lodash.debounce';

const ApplicationList = ({ toast }) => {
  const [datas, setData] = useState([]);
  const [userPage, setUserPage] = useState(1);
  const [searchItem, setSearchItem] = useState("");
  const [loader, setLoader] = useState(true);
  const [modal, contextHolder] = Modal.useModal();
  const [totalDataCount, setTotalDataCount] = useState(0); // This will keep track of total records
  const [currentPage, setCurrentPage] = useState(1);
  const [sort, setSort] = useState({field: 'created_at', order: 'descend'});
  const history = useHistory();
  const itemsPerPage = 10;
  
  const LocalizedModal = (props) => {
    const [open, setOpen] = useState(false);
    const showModal = () => {
      setOpen(true);
    };
    const hideModal = (state) => {
      if (state === 'ok') {
        deleteApplication(props.id)
      }
      setOpen(false);
    };
    return (
      <>
        <Button type="primary" onClick={showModal} danger>
          Delete
        </Button>
        <Modal
          title="Confirm"
          open={open}
          onOk={() => hideModal('ok')}
          onCancel={() => hideModal('cancel')}
          okText="Yes"
          cancelText="Cancel"
        >
          <p>Are you sure you want to delete the application Id <b>{props.applicationId}</b>?</p>
        </Modal>
      </>
    );
  };
  useEffect(() => {
    fetchDetails(1)
    return () => { }
  }, []);
  
  const fetchDetails = (page, sorter, search = null) => {

    setLoader(true);
    const token = localStorage.getItem('token');
    let queryStr = `page=${page}&perPage=${itemsPerPage}`;
    if(sorter && sorter.field && sorter.order) {
      queryStr += `&sortField=${sorter.field}&sortOrder=${sorter.order}`;
    }
    if(search) {
      queryStr += `&search=${search}`;
    }
    fetch(`${APPLICATION_LIST}?${queryStr}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        admin: true,
        "Accept-Language": "EN",
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => {
        if(response.ok) {
          return response.json()
        }
        throw response;
      })
      .then(data => {
        setData(data.data || []);
        setTotalDataCount(data.totaldata);
        setCurrentPage(page);
        setLoader(false);
      }).catch(error => {
        setLoader(false);
        if(error.status && error.status === 401) {
          localStorage.removeItem("token");
          history.push('/logout');
          window.location.reload();
          return;
        } else {
          toast.error('Opps Something went wrong!');
        }
      });
  };

  const generateQR = async (_id) => {
    try {
      const appliationDataIndex = datas.findIndex(d => d._id === _id);
      if (appliationDataIndex >= 0 && datas[appliationDataIndex]) {
        setData((prevData) => {
          const newData = [...prevData];
          newData[appliationDataIndex]['loading'] = true;
          return newData
        });
      }
      const response = await fetch(`${process.env.REACT_APP_API_URL}/application/${_id}/generate-qr`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
      });

      if (response.ok) {
        const data = await response.json();
        if (appliationDataIndex >= 0 && datas[appliationDataIndex]) {
          setData((prevData) => {
            const newData = [...prevData];
            newData[appliationDataIndex]['qrImage'] = data.qrImage;
            newData[appliationDataIndex]['loading'] = false;
            return newData
          });
        }
      } else {
        throw response;
      }
    } catch (error) {
      if(error.status && error.status == 401) {
        localStorage.removeItem("token");
        history.push('/logout');
        window.location.reload();
        return;
    }
      console.error('An error occurred:', error);
    }
  };
  const checkForUploadPdf = file => {
    if (file.type === 'application/pdf') {
      return true;
    } else {
      toast.error('Only PDF file supported.');
      return false;
    }
  }
  const uploadPdf = async (data, _id, state) => {
    try {
      const appliationDataIndex = datas.findIndex(d => d._id === _id);
      if (appliationDataIndex < 0 || !datas[appliationDataIndex]) {
        toast.error('Invalid application selected');
        return;
      }
      setData(prevData => {
        const newData = [...prevData];
        newData[appliationDataIndex]['pdfLoader'] = true;
        return newData;
      });
      const formData = new FormData();
      formData.append('pdf', data.file);
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/application/${_id}/upload-pdf`, formData, {
        onUploadProgress: process => {
          data.onProgress({ percent: (process.loaded / process.total) * 100 })
        },
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
          
        }
      });
      const responseData = response.data;
      data.onSuccess(responseData);
      setData(prevData => {
        const newData = [...prevData];
        newData[appliationDataIndex]['pdf'] = responseData.pdf;
        newData[appliationDataIndex]['pdfLoader'] = false;
        return newData;
      });
      toast.success(`Document ${state} successfully`);
    } catch (error) {
      console.log(error);
      if(error && error instanceof AxiosError) {
        if(error.status === 401) {
          // Logout
          localStorage.removeItem("token");
          history.push('/logout');
          window.location.reload();
          return;
        }
        if(error.response && error.response.data) {
          toast.error(error.response.data.message);
          return;
        }
      }
      toast.error("Could not upload the document.");
      data.onError(error);
    }
  }
  const download = (_id, fileUrl, fileName, type) => {
    try {
      const applicationIndex = datas.findIndex(d => d._id === _id);
      if (applicationIndex < 0 || !datas[applicationIndex]) {
        toast.error('Invalid application');
        return;
      }
      const loaderKey = type === 1 ? 'downloadQRLoading' : 'downloadPDFLoading';
      // set the loading
      setData(prevData => {
        const newData = [...prevData];
        newData[applicationIndex][loaderKey] = true;
        return newData;
      });
      const link = document.createElement('a');
      link.href = fileUrl;
      link.setAttribute(
        'download',
        `${fileName}`
      );
      link.setAttribute('target', '_blank');
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      // loading false
      setData(prevData => {
        const newData = [...prevData];
        newData[applicationIndex][loaderKey] = false;
        return newData;
      });
    } catch (e) {

    }
  }
  const deleteApplication = async (_id) => {
    try {
      const applicationIndex = datas.findIndex(d => d._id === _id);
      if (applicationIndex < 0) {
        toast.error('Invalid application');
        return;
      }
      const appIdData = datas[applicationIndex];
      // setData((prevData) => {
      //   const newData = [...prevData];
      //   newData[applicationIndex]['deleteLoader'] = true;
      //   return newData;
      // });
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/application/${_id}/delete`, {}, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`

        },
      });

      if (response.status === 200) {
        const newData = datas.filter((data) => data._id.toString() !== _id.toString());
        setData(newData);
        toast.success(`Application ID ${appIdData.applicationId} deleted successfully.`);
      } else {
        toast.error('Failed to delete application.');
      }
    } catch (error) {
      if (error && error instanceof AxiosError && error.response && error.response.data) {
        if(error.status === 401) {
          // Logout
          localStorage.removeItem("token");
          history.push('/logout');
          window.location.reload();
          return;
        }
        if(error.response && error.response.data) {
          toast.error(error.response.data.message);
          return;
        }
      }
      toast.error(error.toString());
    }
  }
  const columns = [
    {
      title: "SL.No",
      dataIndex: "",
      key: "sno",
      render: (text, record, index) => ((currentPage - 1) * itemsPerPage) + index + 1,
      width: '5%',
    },
    {
      title: "Date",
      dataIndex: "created_at", // Assuming the data has a 'date' field
      render: (date) => new Date(date).toLocaleDateString(), // Format: "DD-MM-YYYY"
      width: '10%',
      sorter: true,
      defaultSortOrder:"descend",
      sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
      title: "Application ID",
      dataIndex: "applicationId",
      render: (appId) => {
        // 
        return <>
          {appId.length > 20 ?
            appId.substring(0, 20) + '...'
            :
            appId}
        </>
      },
      width: '25%',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
      title: "QR Code",
      dataIndex: "qrImage",
      render: (qrImage, record) => (
        qrImage ?
          <div>
            <img src={qrImage} alt="QR Code" style={{ width: '100px', height: '100px' }} />
            <br />
            <Button type="dashed" icon={<DownloadOutlined />} size={"middle"} loading={record.downloadQRLoading} onClick={() => download(record._id, qrImage, `${record.applicationId}_QR.png`, 1)}>
              Download QR Code
            </Button>
          </div>
          :
          <>
            <Button type="primary" loading={record.loading} size={"middle"} onClick={() => generateQR(record._id)}>
              Generate QR Code
            </Button>
          </>
      )
    },
    {
      title: "Upload Document",
      dataIndex: "pdf",
      render: (pdf, record) => {
        return pdf
          ?
          <>
            <Button type="dashed" icon={<DownloadOutlined />} size={"middle"} loading={record.downloadPDFLoading} onClick={() => download(record._id, pdf, `${record.applicationId}_Document.pdf`, 2)}>
              Download
            </Button><br />
            <Upload accept="application/pdf" beforeUpload={(file) => checkForUploadPdf(file)} customRequest={(data) => { uploadPdf(data, record._id, 'changed') }} showUploadList={false}>
              <Button type="primary" style={{ 'marginTop': '5px' }} icon={<UploadOutlined />} loading={record.pdfLoader}>
                Change Document
              </Button>
            </Upload>
          </>
          :
          record.qrImage ? <>
            <Upload accept="application/pdf" beforeUpload={(file) => checkForUploadPdf(file)} customRequest={(data) => { uploadPdf(data, record._id, 'uploaded') }} showUploadList={false}>
              <Button type="primary" icon={<UploadOutlined />} loading={record.pdfLoader}>
                Upload Document
              </Button>
            </Upload>
          </> : <>Please generate QR first</>;
      }
    },
    {
      title: "Actions",
      dataIndex: "pdf",
      render: (pdf, record) => {
        return (
          <>
            <Space>
              <LocalizedModal id={record._id} applicationId={record.applicationId} />
              {/* <Button  type="primary" danger style={{'marginTop': '5px'}} loading={record.deleteLoader} onClick={() => {confirm(record._id)}}>Delete</Button> */}
            </Space>
            {contextHolder}
          </>)
      }
    }
  ];

  const handleExcelDownload = async () => {
    try {
      const token = localStorage.getItem('token'); // get the token if required
      const url = `${process.env.REACT_APP_API_URL}/application/generate-excel`;
      const response = await fetch(url, {
        headers: {
          "Authorization": `Bearer ${token}` // assuming you require authorization
        }
      });
      if(!response.ok) {
        throw response;
      }
      const blob = await response.blob();
      const link = document.createElement('a');

      link.href = window.URL.createObjectURL(blob);
      link.download = "ApplicationList.xlsx";

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      if(error.status && error.status == 401) {
        localStorage.removeItem("token");
        history.push('/logout');
        window.location.reload();
        return;
      }      
      toast.error("Error downloading the excel file.");
      console.error(error);
    }
  };

  const handleSort = (pagination, filter, sorter) => {
    if(currentPage !== pagination.current) {
      fetchDetails(pagination.current, {...sort});
    } else {
      if(sorter && sorter.field && sorter.order) {
        setSort({
          field: sorter.field,
          order: sorter.order
        });
        fetchDetails(1, {...sorter});
      }
    }
  }
  const handleSearch = (e) => {
    const search = e.target.value;
    fetchDetails(1, {field: sort.field, order: sort.order}, search);
  }
  const debounceEvent = useMemo(() => {
    return debounce(handleSearch, 300);
  }, []);
  useEffect(() => () => {
    debounceEvent.cancel();
  });

  return (
    <div>
      <div className="table-temp-header">
        <Row gutter="24" className="align-items-center">
          <Col span={14}>
            <h2 className="activity-title">Application ID Listing</h2>
          </Col>
          <Col span={10} align={'end'} justify={'center'}></Col>
          <Col span={10} align={'end'} justify={'center'} style={{marginBottom: '10px'}}>  
            <div className="search-wrap" >
                <span className="search-btn">
                  <SearchOutlined />
                </span>
                <Input placeholder="Search Application ID" onChange={debounceEvent} />
              </div>
          </Col>
          <Col span={14} align={'end'} justify={'center'} style={{marginBottom: '10px'}}>
            <Button
                type="primary"
                icon={<FileExcelOutlined />}
                onClick={handleExcelDownload}
                className="margin-right-btn">
                Export to Excel
              </Button>
              <Button type="primary"><Link to="/application-create">Add Application</Link></Button>

          </Col>
        </Row>
      </div>
      <Row>
        <Col span={24}>
          <div className="table-responsive">
            {/* <InfiniteScrollCustom
              endMessage
              style={{ maxHeight: "350px", minHeight: "750px" }}
              sendPage={setUserPage}
              targetedElem="tableData"
              height={480}
              data={datas}
              page={userPage}
            > */}
              <Table
                rowClassName="hoverElem"
                className="sticky-header text-break align-items-center"
                columns={columns}
                dataSource={datas}
                rowKey="_id"
                locale={{
                  emptyText: loader
                    ? <TableContentLoaderWith columnWidth={[25, 25, 50]} />
                    : notFoundContentComponent(searchItem)
                }}
                loading={loader}
                pagination={{
                  current: currentPage,
                  pageSize: itemsPerPage,
                  total: totalDataCount,
                  onChange: (page) => {
                    // fetchDetails(page)
                    // console.log('PAGE: ', page)
                  }
                }}
                onChange={(pagination, filters, sorter) => {
                  handleSort(pagination, filters, sorter);
                }}
              />
            {/* </InfiniteScrollCustom> */}
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default ApplicationList;