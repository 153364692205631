import { Button, Col, Input, Row, Table, Upload, Modal, Space } from "antd";
import React, { useEffect, useState } from "react";
import { notFoundContentComponent } from "../../../sharedComponents/NotContentFound";
import { TableContentLoaderWith } from "../../../sharedComponents/TableContentLoader";
import InfiniteScrollCustom from "../../../sharedComponents/InfiniteScroll";
import { APPLICATION_TRASH_LIST } from "../../../all-api/api"
import axios, { AxiosError } from 'axios';
import { useHistory } from 'react-router-dom';


const ApplicationTrashList = ({ toast }) => {
  const [datas, setData] = useState([]);
  const [userPage, setUserPage] = useState(1);
  const [loader, setLoader] = useState(true);
  const [totalDataCount, setTotalDataCount] = useState(0); // This will keep track of total records
  const [currentPage, setCurrentPage] = useState(1);
  const [modal, contextHolder] = Modal.useModal();
  const history = useHistory();
  const itemsPerPage = 10;
  useEffect(() => {
    fetchDetails(1)
    return () => { }
  }, []);

  const fetchDetails = (page) => {
    setLoader(true);
    const token = localStorage.getItem('token');
    fetch(`${APPLICATION_TRASH_LIST}?page=${page}&perPage=${itemsPerPage}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        admin: true,
        "Accept-Language": "EN",
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => {
        if(response.ok) {
          return response.json()
        } 
        throw response;
      })
      .then(data => {
        setData(data.data || []);
        setTotalDataCount(data.totaldata);
        setCurrentPage(page);
        setLoader(false);
      }).catch(error => {
        console.log(error);
        if(error.status && error.status == 401) {
          localStorage.removeItem("token");
          history.push('/logout');
          window.location.reload();
          return;
      }
      });
  };
  const restoreApplication = async (_id) => {
    try {
      const applicationIndex = datas.findIndex(d => d._id === _id);
      if (applicationIndex < 0) {
        toast.error('Invalid application');
        return;
      }
      const appIdData = datas[applicationIndex];
      setData((prevData) => {
        const newData = [...prevData];
        newData[applicationIndex]['loader'] = true;
        return newData;
      });
      const token = localStorage.getItem('token');
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/application/restore`, {id: _id}, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
      });

      if (response.status === 200) {
        const newData = datas.filter((data) => data._id.toString() !== _id.toString());
        setData(newData);
        toast.success(`Application ID ${appIdData.applicationId} restored successfully.`);
      } else {
        setData((prevData) => {
          const newData = [...prevData];
          newData[applicationIndex]['loader'] = false;
          return newData;
        });
        toast.error('Failed to restore application id.');
      }
    } catch (error) {
      if (error && error instanceof AxiosError) {
        if(error.status === 401) {
          // Logout
          localStorage.removeItem("token");
          history.push('/logout');
          window.location.reload();
          return;
        }
        if(error.response && error.response.data) {
          toast.error(error.response.data.message);
          return;
        }
      }
      toast.error(error.toString());
    }
  }
  const LocalizedModal = (props) => {
    const [open, setOpen] = useState(false);
    const [btnLoader, setBtnLoader] = useState(false);
    const showModal = () => {
      setOpen(true);
    };
    const hideModal = (state) => {
      if (state === 'ok') {
        setBtnLoader(true);
        restoreApplication(props.id)
      }
      setOpen(false);
    };
    return (
      <>
        <Button type="primary" onClick={showModal} loading={btnLoader}>
          Restore
        </Button>
        <Modal
          title="Confirm"
          open={open}
          onOk={() => hideModal('ok')}
          onCancel={() => hideModal('cancel')}
          okText="Yes"
          cancelText="Cancel"
        >
          <p>Are you sure you want to restore the application Id <b>{props.applicationId}</b>?</p>
        </Modal>
      </>
    );
  };
  const columns = [
    {
      title: "SL.No",
      dataIndex: "",
      key: "sno",
      render: (text, record, index) => ((currentPage - 1) * itemsPerPage) + index + 1,
      width: '5%',
    },
    
    {
      title: "Application ID",
      dataIndex: "applicationId",
      render: (appId) => {
        // 
        return <>
          {appId.length > 20 ?
            appId.substring(0, 20) + '...'
            :
            appId}
        </>
      },
      width: '45%',
    },
    {
      title: "Deleted On",
      dataIndex: "deleted_at", // Assuming the data has a 'date' field
      render: (date) => date ? new Date(Date.parse(date)).toLocaleDateString() : 'N/A', // Format: "DD-MM-YYYY"
      width: '20%',
    },
    {
      title: "Actions",
      dataIndex: "_id",
      render: (_id, record) => {
        return (
          <>
            <Space>
              <LocalizedModal id={_id} applicationId={record.applicationId} />
              {/* <Button  type="primary" success style={{'marginTop': '5px'}} loading={record.loader} onClick={() => {restoreApplication(_id)}}>Restore</Button> */}
            </Space>
            {contextHolder}
          </>)
      }
    }
  ];


  return (
    <div>
      <div className="table-temp-header">
        <Row gutter="24" className="align-items-center">
          <Col span={14}>
            <h2 className="activity-title m-0">Application ID Trash</h2>
          </Col>
          <Col span={10} align={'end'} justify={'center'}>
          </Col>
        </Row>
      </div>
      <Row>
        <Col span={24}>
          <div className="table-responsive">
            {/* <InfiniteScrollCustom
              endMessage
              style={{ maxHeight: "350px", minHeight: "750px" }}
              sendPage={setUserPage}
              targetedElem="tableData"
              data={datas}
              page={userPage}
            > */}
              <Table
                rowClassName="hoverElem"
                className="sticky-header text-break align-items-center"
                columns={columns}
                dataSource={datas}
                rowKey="_id"
                locale={{
                  emptyText: loader
                    ? <TableContentLoaderWith columnWidth={[10, 45, 25, 20]} />
                    : notFoundContentComponent(false)
                }}
                loading={loader}
                pagination={{
                  current: currentPage,
                  pageSize: itemsPerPage,
                  total: totalDataCount,
                  onChange: (page) => fetchDetails(page)
                }}
              />
            {/* </InfiniteScrollCustom> */}
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default ApplicationTrashList;