import "./App.css";
import Login from "./components/auth/Login";
import { BrowserRouter, Switch, Route,Redirect } from "react-router-dom";
import Home from "./components/dashboard/Home";
import PageNotFound from "./sharedComponents/PageNotFound";
import PrivateRoute from './context/ProtectedRoute';
import WrongApp from "./components/dashboard/WrongApp";
import Logout from "./components/auth/Logout";
import Policy from "./components/dashboard/policy/Policy";
function App() {
  const isAuthenticated = !!localStorage.getItem("token");

  return (
    <BrowserRouter>
      <div>
        <Switch>
          <Route
            exact
            path="/"
            render={() =>
              isAuthenticated ? <Redirect to="/dashboard" /> : <Login />
            }
          />
          <PrivateRoute exact path="/dashboard" component={Home} />
          <PrivateRoute exact path="/profile" component={Home} />
          <PrivateRoute exact path="/application-listing" component={Home} />
          <PrivateRoute exact path="/application-create" component={Home} />
          <PrivateRoute exact path="/application-trash" component={Home} />
          <PrivateRoute exact path="/change-password" component={Home} />
          <PrivateRoute exact path="/logout" component={Logout} />
          <Route exact path="/scan-wrong-app" component={WrongApp} />
          <Route exact path="/privacy-policy" component={Policy} />
          <Route path="*">
            <PageNotFound />
          </Route>
        </Switch>
      </div>
    </BrowserRouter>
  );
}

export default App;
