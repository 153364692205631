import { Button, Form, Input } from "antd";
import React from "react";
import logo from "../assets/images/HIDCO_LOGO_LOGIN_PAGE.jpg";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LOGIN_API } from "../../all-api/api"

async function loginUser(credentials) {
  return await fetch(LOGIN_API, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      admin: true,
      "Accept-Language": "EN",
    },
    body: JSON.stringify(credentials),
  }).then((data) => data.json());
}

const Login = () => {
  toast.configure({
    autoClose: 2500,
    draggable: false,
  });

  let history = useHistory();

  const onFinish = async (values) => {
    const { email, password } = values;
    const response = await loginUser({
      email,
      password,
    });
    localStorage.setItem("user_details",values.email);
    if (response && response.accessToken) {
      localStorage.setItem('token', response.accessToken);
      toast.success(response.message || 'Success');
      history.push("/dashboard");
    } else {
      toast.error(response.message || 'Something went wrong');
    }
    
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    window.location.reload(false);
    toast.error("Opps! Something went wrong!");
  };
  return (
    <div className="login-wrapper">
      <div className="content">
        <div className="login-logo">
          <img src={logo} alt="Service Reminder" className="login-logo img" />
          <p style={{textAlign:'centre', marginTop: '0px',marginBottom: '15px', fontWeight: 'bold', fontSize: '17px' }}>WBHIDCO Property Data Search</p>
        </div>
        <div className="login-form">
          <Form
            name="login-form"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <h3 className="text-title">Log in to access the admin panel</h3>
            <Form.Item
              name="email"
              rules={[{ required: true, message: "Please input your Email!" }]}
            >
              <Input placeholder="Email" />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[
                { required: true, message: "Please input your password!" },
              ]}
            >
              <Input.Password placeholder="Password" />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
              >
                LOGIN
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Login;