import { FrownOutlined } from '@ant-design/icons';
import React from 'react';

export const notFoundContentComponent = (searchItem) => {
  return (
    searchItem
      ? <div className='text-center'> 
        <FrownOutlined style={{ fontSize:'30px', color: '#CED2D6' }} /> 
        <p style={{marginTop:'15px'}}> No results were found matching {searchItem} </p> 
      </div>
      :   <div className='text-center'> 
        <FrownOutlined style={{ fontSize:'30px', color: '#CED2D6' }} /> 
        <p style={{marginTop:'15px'}}> Looks like you dont have any records yet</p> 
      </div>
  );
};