import React, { useState } from "react";
import { Line, Column } from "@ant-design/plots";
import { Button, Select } from "antd";
const { Option } = Select;
let chart;



const Dashboard = () => {
  const tokenString = localStorage.getItem("token") || "{}";
  const userToken = /*JSON.parse(tokenString);*/ tokenString


  const data = [
    {
      type: "Value 1",
      value: 315801
    },
    {
      type: "Value 2",
      value: 222095
    },
    {
      type: "Value 3",
      value: 10800
    }
  ];

  const config = {
    data,
    loading: data.length === 0,
    xField: "type",
    yField: "value",
    seriesField: "type",
    yAxis: {
      label: {
        formatter: (v) => v
      }
    },
    xAxis: false,
    height: 200,
    autoFit: false,
    legend: {
      position: "bottom",
      flipPage: false
    },
    interactions: [
      {
        type: "element-active"
      }
    ],
    label: {
      position: "top",
      offsetY: 8,
      formatter: ({ value }) => value
    }
  };

  return (
    <div className="index-dashbord">
      <h1 className="hometype">
        Welcome to the WBHIDCO
      </h1>
      {/* <div className="container-hei">
      <Column {...config} />
      </div> */}
    </div>
  );
};

export default Dashboard;