import React, {useRef} from 'react';
import { Button, Col, Input, Row, Form, Card } from "antd";
import { CHANGE_PASSWORD_API } from '../../../all-api/api';
import axios, { AxiosError } from 'axios';
import { useHistory } from 'react-router-dom';

const ChangePassword = ({toast}) => {
    const history = useHistory();
    const form = useRef(null);
    const onFinish = (values) => {
        changePassword({...values});
      };
      const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
      };
      const changePassword = ({current_password, new_password}) => {
        axios.post(CHANGE_PASSWORD_API, {current_password, new_password}, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(response => {
            if(response.status === 200) {
                if(response.data.error) {
                    toast.error(response.data.message);
                } else {
                    toast.success(response.data.message);
                    if(form.current) {
                        form.current.resetFields();
                    }
                }
            }
        }).catch(error => {
            if(error instanceof AxiosError) {
                if(error.status === 401) {
                    localStorage.removeItem("token");
                    history.push('/logout');
                    window.location.reload();
                    return;
                }
                if(error.response && error.response.data) {
                    toast.error(error.response.data.message);
                    return;
                }
            }
        })
      }

      const validateConfirmPassword = (rule, value, callback) => {
        if (value && value !== form.current.getFieldValue('new_password')) {
            callback('The two passwords that you entered do not match!');
          } else {
            callback();
          }
      }
    return (
        <div>
            <Row>
                <Col span={14}>
                    <h2 className="activity-title m-0">Change Password</h2>
                </Col>
            </Row>
            <Card>
                <Row align={'center'} justify={'center'}>
                    <Col lg={12} sm={12}>
                        <Form
                            ref={r => {
                                form.current = r;
                            }}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                        >
                            <Form.Item 
                                label=""
                                name="current_password"
                                rules={[
                                {
                                    required: true,
                                    message: 'Please input your current password!',
                                },
                                ]}
                            >
                                <Input.Password placeholder='Current Password'/>
                            </Form.Item>
                            <Form.Item 
                                label=""
                                name="new_password"
                                rules={[
                                {
                                    required: true,
                                    message: 'Please input your new password!',
                                },
                                ]}
                            >
                                <Input.Password placeholder="New password"/>
                            </Form.Item>
                            <Form.Item 
                                label=""
                                name="confirm_password"
                                rules={[
                                {
                                    required: true,
                                    message: 'Please input your confirm password!',
                                },
                                {
                                    validator:  validateConfirmPassword
                                }
                                ]}
                            >
                                <Input.Password placeholder='Confirm Password'/>
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" htmlType="submit">
                                    Submit
                                </Button>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
            </Card>
        </div>
    )
};

export default ChangePassword;